import { LOG_LEVEL, loadTranslation } from '@b2x/core/bundle';

import { initializeAlertListener } from './handlers/alert-user-menu';
import { handleNavigationItem } from './handlers/navigation-item';
import { LOG_GROUPS, logger } from './utilities/logger';
import { buildTime, version } from './utilities/version';

async function bootstrap() {
  await loadTranslation('a6b17e3617922fdb78965d810593d828');

  logger(`One Header Integrator ${version} - ${buildTime}`, {
    args: [],
    group: LOG_GROUPS.BOOTSTRAP,
    level: LOG_LEVEL.INFO,
  });

  try {
    handleNavigationItem();
    initializeAlertListener();
  } catch (error) {
    logger('Error while integrating OneHeader with User Menu', {
      args: [error],
      group: LOG_GROUPS.BOOTSTRAP,
      level: LOG_LEVEL.WARN,
    });
  }
}

window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    bootstrap();
  }
});

bootstrap();
