import {
  type AuthenticationState,
  IdentityProvider,
  isLoggedIn,
  isOnBehalfModeActive,
} from '@b2x/authentication-library';
import { getComponentRegistryConfig, useTranslations } from '@b2x/core/bundle';

import type { Profile } from '@b2x/profile-library';
import type { HeaderNavigationItemsConfiguration } from '@dh-io-hp/header-connection';
import type { ComponentsState } from '@owc/component-registry-connection';

interface UmConfig extends ComponentsState {
  payload?: {
    loginLabel?: string;
    userProfileAria?: string;
    isProfileSelectionEnabled?: boolean;
    menuEntriesPrivate?: Record<string, unknown>;
    overviewEntriesPrivate?: Record<string, unknown>;
    settingsEntriesPrivate?: Record<string, unknown>;
  };
}

type Unpacked<T> = T extends (infer U)[] ? U : T;
type ItemConfig = Unpacked<HeaderNavigationItemsConfiguration>;
type HeaderPayload = Array<{ subLabel?: string } & ItemConfig>;

export const getUserMenuConfig = async () => {
  const [userMenuConfig] = await getComponentRegistryConfig<Partial<UmConfig[]>>((components) => {
    const registry = components.find((component) => {
      const payload = component?.payload;
      return (
        payload &&
        (payload.menuEntriesPrivate ||
          payload.overviewEntriesPrivate ||
          payload.settingsEntriesPrivate ||
          payload.userProfileAria)
      );
    });
    return registry ? [registry] : [];
  });

  return {
    loginLabel: userMenuConfig?.payload?.loginLabel || 'Login',
    isProfileSelectionEnabled: Boolean(userMenuConfig?.payload?.isProfileSelectionEnabled),
  };
};

export function getNavItemConfig(
  activeProfile: Profile,
  authState: AuthenticationState,
  menuConfig: UmConfig,
): HeaderPayload {
  if (authState.identityProvider === IdentityProvider.CIAS) {
    return getConfigForNormalUser(authState, activeProfile, menuConfig);
  }

  if (isOnBehalfModeActive(authState)) {
    return getConfigForNormalUser(authState, activeProfile, menuConfig);
  }
  return getConfigForSalesAgent(authState, activeProfile, menuConfig);
}

function getConfigForNormalUser(
  authState: AuthenticationState,
  activeProfile: Profile,
  menuConfig: UmConfig,
): HeaderPayload {
  // biome-ignore lint/correctness/useHookAtTopLevel: <explanation>
  const { t } = useTranslations();

  const { loginLabel, isProfileSelectionEnabled } = menuConfig;

  let fullName = activeProfile.displayName ?? '';
  let subLabel = '';
  let label = loginLabel;

  if (isLoggedIn(authState)) {
    // B2C private profile
    if (activeProfile.businessType === 'B2C') {
      label = fullName;
      // WARNING: these translation keys may not match logical expectations
      subLabel = t('profile.context.private.guest');
    }
    // B2B company profile
    else if (fullName) {
      label = fullName;
      fullName = fullName.charAt(0);
      // WARNING: these translation keys may not match logical expectations
      subLabel = t('profile.context.company.guest');
    }
    // B2B generic profile
    else {
      // WARNING: these translation keys may not match logical expectations
      label = t('profile.context.company.guest');
    }
  }
  // Logged out
  else if (activeProfile.businessType === 'B2C') {
    subLabel = t('profile.context.private.guest');
  } else {
    subLabel = t('profile.context.company.guest');
  }

  return [
    {
      name: 'iam-user-menu-v3',
      label,
      subLabel: isProfileSelectionEnabled ? subLabel : undefined,
      avatar: {
        fullName,
        icon: activeProfile.businessType === 'B2B' ? 'company/24' : 'bds/user/24',
      },
    },
  ];
}

function getConfigForSalesAgent(
  authState: AuthenticationState,
  activeProfile: Profile,
  menuConfig: UmConfig,
): HeaderPayload {
  const { loginLabel } = menuConfig;
  const fullName = activeProfile.displayName ?? '';

  return [
    {
      name: 'iam-user-menu-v3',
      label: isLoggedIn(authState) ? fullName : loginLabel,
      subLabel: undefined,
      avatar: {
        icon: 'bds/user/24',
      },
    },
  ];
}
